<template>
  <FounderPitchNavigation />
  <router-view />
</template>

<script>
import FounderPitchNavigation from "../components/FounderPitchNavigation";
export default {
  name: "FounderProfile",
  components: { FounderPitchNavigation },
};
</script>

<style scoped></style>
