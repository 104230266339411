
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import PublicPitch from "@/founder/components/PublicPitchDrawer.vue";
import { useStore } from "vuex";
import { ElNotification } from "element-plus";
import { updateFounderProfileImage } from "@/api/founder.api";
import { getAuth } from "firebase/auth";

export default defineComponent({
  name: "FounderPitchNavigation",
  components: {
    PublicPitch,
  },
  setup() {
    const store = useStore();

    const uploadProfileImageLoading = ref(false);
    const founderProfileImageUrlLocal = ref("");

    const founder = computed(() => {
      return store.getters["FounderModule/getFounder"];
    });

    const founderProfileImageUrl = computed(() => {
      return store.getters["FounderModule/getFounderProfileImageUrl"];
    });

    const handleFounderProfileImageUpload = async (event) => {
      let uploadedImage = event.target.files[0];
      if (uploadedImage.type.match("image.*")) {
        uploadProfileImageLoading.value = true;
        let preservedImage;
        try {
          preservedImage = founderProfileImageUrlLocal.value;
          founderProfileImageUrlLocal.value =
            URL.createObjectURL(uploadedImage);
          const formData = new FormData();
          formData.append("photo", uploadedImage);
          await updateFounderProfileImage(formData);
          await store.dispatch("UserModule/fetchUserAndSave");
        } catch (e) {
          console.log(e);
          founderProfileImageUrlLocal.value = preservedImage;
          ElNotification({
            title: "Error",
            message: "Please uploading profile image",
            type: "error",
          });
        } finally {
          uploadProfileImageLoading.value = false;
        }
      } else {
        ElNotification({
          title: "Error",
          message: "Please attach a valid image",
          type: "error",
        });
      }
    };

    onMounted(async () => {
      //
    });

    watch(
      () => founderProfileImageUrl.value,
      (value) => {
        founderProfileImageUrlLocal.value = value;
      },
      { immediate: true }
    );

    return {
      uploadProfileImageLoading,
      founderProfileImageUrlLocal,
      founder,
      handleFounderProfileImageUpload,
      getAuth,
    };
  },
});
